@import '@lumapps/lumx/scss/variables.scss';

$micro-app-loading-height: 400px;

.micro-app {
    padding: $lumx-spacing-unit-huge;
    background-color: initial!important;
    &--loading {
        display: flex;
        min-height: $micro-app-loading-height;
    }
}
